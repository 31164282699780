












































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class Register extends Vue {
  public email = "";
  public password = "";
  public password2 = "";
  public loading = false;

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  private async login() {
    this.loading = true;
    await this.delay(2000);
    this.$router.push({ name: "Home" });
  }
}
